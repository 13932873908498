<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title text-left">
          All unassigned applications&nbsp;&nbsp;<span
            class="text-color-orange"
        >{{ unassignedAppCount }}</span
        >
        </h5>
        <LoadingSection :loading-state="unassignedAppCount">
          <ScrollerWrap
              id="ScrollerWrap_1"
              :next="getUnassignedApplications?.next_page_url"
              class="-height-40 -bottom-padding-large"
              @update-list="handleLazyUpdate"
          >
            <ApplicationList
                :assignment-usage="true"
                :min-cols="true"
                :search-application="getUnassignedApplications?.data"
                @selected="handleApplicationSelect"
                @list-status-change="gatherApplicationData"
                @duplicate-found="handleDuplication"
            />
          </ScrollerWrap>
        </LoadingSection>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ApplicationList from "@/components/lists/ApplicationList";
import ScrollerWrap from "@/components/misc/ScrollerWrap";
import LoadingSection from "@/components/loader/LoadingSection";

export default {
  name: "ApplicationAssignment",
  emits: ["application-assigned"],
  data: () => ({
    lazyData: null
  }),
  components: {
    ApplicationList,
    LoadingSection,
    ScrollerWrap
  },
  watch: {
    async getPusherNotification(param) {
      switch (param.type) {
        case 'assign-application':
          await this.fetchUnassignedApplications().catch(err => console.log(err));
          await this.fetchAssignedApplications().catch(err => console.log(err));
          break;
      }
    }
  },
  computed: {
    ...mapGetters(["getUnassignedApplications", "getPusherNotification"]),
    unassignedAppCount() {
      if (this.getUnassignedApplications == null) return;
      return this.getUnassignedApplications.total;
    }
  },
  methods: {
    ...mapActions(["fetchUnassignedApplications", "fetchAssignedApplications"]),
    ...mapMutations(["setApplicationUuid"]),
    handleLazyUpdate(data) {
      if (typeof data === 'undefined') return;
      this.lazyData = this.getUnassignedApplications
      this.lazyData.next_page_url = data.next_page_url
      this.lazyData.data = this.lazyData.data.concat(data.data)
    },
    handleDuplication({uuid, duplicate_type}) {
      this.setApplicationUuid(uuid);
      this.$router.push({
        name: "Duplication Manager",
        params: {
          auuid: uuid,
          type: duplicate_type
        }
      });
    },
    gatherApplicationData() {
      console.log("ApplicationAssignment.gatherApplicationData");
      this.$emit("application-assigned");
    },
    handleApplicationSelect(uuid) {
      this.setApplicationUuid(uuid);
      this.$router.push({
        name: "Application Focus",
        params: {
          uuid: uuid
        }
      });
    }
  },
  async beforeMount() {
    await this.fetchUnassignedApplications().catch(err => console.log(err));
  },
  mounted() {
    // this.getNextUser();
  }
};
</script>
<style lang="scss" scoped>
#ScrollerWrap_1 {
  //height: 25vh;
}
</style>
