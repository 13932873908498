<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title text-left">
        <div class="row">
          <div class="col-6">
            <h5 class="text-header mb-4">{{ title }} list&nbsp;&nbsp;<span class="text-color-orange">{{
                count?.value
              }}</span></h5>
          </div>
          <div class="col-6">
            <SelectInput :options="providerOptions" class="form-select form-select-sm mb-0" label="" name=""
                         placeholder="" @selected="handleSearchProviderOptions"/>
          </div>
        </div>
      </h5>

      <ScrollerWrap :constrain-height="true">
        <div>
          <div v-if="lorDataLength" class="table-responsive">
            <table class="table table-hover text-left">
              <thead>
              <tr>
                <th class="pl-0" scope="col">Customer Name</th>
                <th scope="col">Provider</th>
                <th v-if="showTranslate" scope="col">&nbsp;</th>
                <th scope="col">Application start date</th>
                <th scope="col">Trace&nbsp;ID</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(tp, index) in filteredLorData" :key="index" class="table-row">
                <td class="pr-0 pl-0">{{ tp.first_name }} {{ tp.last_name }}</td>
                <td class="pr-0" @click="handlePensionNav(tp)">
                  <span class="link-text">{{ getProviderDetails(tp) }}</span>
                </td>
                <td v-if="showTranslate" class="pr-0">
                  <button class="btn btn-success btn-sm btn-top-margin" @click.prevent="handleTranslateNav(tp)">
                    view&nbsp;translate
                  </button>
                </td>
                <td class="pr-0">
                  {{ formatReadDateTime(tp.created_at) }}
                </td>
                <td class="pr-0">
                  <span class="text-subdued">{{ tp.id }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="mt-3">No records found!</div>
        </div>
      </ScrollerWrap>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ScrollerWrap from "@/components/misc/ScrollerWrap"

export default {
  name: "LORListByStatus",
  components: {ScrollerWrap},
  props: {
    ref: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    showTranslate: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    missingText: "Not available",
    lorData: [],
    count: 0,
    selectedProvider: ''
  }),
  computed: {
    ...mapGetters([
      "getStatusTPValues"
    ]),
    lorDataLength() {
      return this.lorData.length > 0;
    },
    filteredLorData() {
      return this.selectedProvider !== '' ? this.lorData.filter(tp => {
        return tp?.name?.toLowerCase() == this.selectedProvider.toLowerCase();
      }) : this.lorData
    },
    providerOptions() {
      return this.lorData.map(tp => {
        return {
          text: tp?.name,
          id: tp?.name
        }
      }).filter(
          (elem, index, arr) => index === arr.findIndex((t) => t.id === elem.id)
      ).sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    }
  },
  methods: {
    ...mapActions([
      "updateTracedPension",
      "getPensionsByStatus",
    ]),
    handleTranslateNav(tp) {
      this.$router.push({
        name: 'Fabric Translate Extracted Pages',
        params: {
          auuid: tp.auuid,
          tpuuid: tp.uuid,
        }
      });
    },
    handlePensionNav(tp) {
      this.$router.push({
        name: 'Traced Pension',
        params: {
          uuid: tp.auuid,
          tpuuid: tp.uuid,
        }
      });
    },
    getProviderParent(tp) {
      return tp?.current_provider?.current_parent || null;
    },
    getProviderName(tp) {
      return tp?.current_provider?.name || this.missingText;
    },
    getProviderDetails(tp) {
      return tp?.name || this.missingText;
    },
    async handleStatus(param, id) {
      let tempParams = {};
      let provider = {
        id,
        status: param.status,
        closed_reason: param.closed_reason
      };
      tempParams.pensions = [];
      tempParams.pensions.push({
        provider
      });
      tempParams.updatePensionForm = true;

      await this.updateTracedPension({tempParams}).catch(err =>
          console.log(err)
      );

      this.$refs["statusUpdateComponent_" + id].edit = false;
      this.$refs["statusUpdateComponent_" + id].updating = false;
    },
    async fetchData() {
      let searchParams = {
        status: this.status
      };

      let response = await this.getPensionsByStatus({searchParams}).catch(err => console.log(err));

      if (response.status === 200) {

        this.lorData = response.data.data;
        this.count = response.data.count;
      }
    },
    handleSearchProviderOptions(value) {
      this.selectedProvider = value;
    },
  },
  async beforeMount() {
    await this.fetchData()
  }
}
</script>
<style lang="scss">
</style>