<template>
  <div class="dashboard container-fluid h-auto">
    <div class="row">
      <div class="col-12 mb-0">
        <TitleHeader>
          <img
              :src="Logo"
              class="-arrow"
              width="100"
          />
        </TitleHeader>
      </div>
      <div class="col-lg-6 col-12 mb-4">
        <ApplicationAssignment @application-assigned="gatherApplicationData"/>
      </div>
      <!------------------------------------------------------------------------------------------->
      <div class="col-lg-6 col-12 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title text-left">
              My assigned applications&nbsp;&nbsp;<span
                class="text-color-orange"
            >{{ assignedAppCount }}</span
            >
            </h5>
            <ScrollerWrap class="-height-40">
              <LoadingSection :loading-state="assignedAppCount">
                <ApplicationList
                    :min-cols="true"
                    :pre-loaded="true"
                    :search-application="getAssignedApplications"
                    :show-status="true"
                    :unassignment-usage="true"
                    @selected="handleApplicationSelect"
                    @list-status-change="gatherApplicationData"
                />
              </LoadingSection>
            </ScrollerWrap>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-12 mb-2">
        <LORListByStatus
            :show-translate="false"
            status="Too Long Scheme Name"
            title="Pension report failed to generate"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import ApplicationAssignment from "@/components/application-manager/ApplicationAssignment";
import ApplicationList from "@/components/lists/ApplicationList";
import ScrollerWrap from "@/components/misc/ScrollerWrap";
import LoadingSection from "@/components/loader/LoadingSection";
import Logo from '@/assets/fabric_logo-sm.3c9dbf09.png';
import LORListByStatus from "@/components/lists/LORListByStatus.vue";

export default {
  name: "Home",
  data() {
    return {
      Logo,
      tabs: [
        {title: 'Applications', value: 'applications'},
        {title: 'LORs', value: 'lors'}
      ],
      currentTab: 'applications',
    };
  },
  watch: {
    async getPusherNotification(param) {
      switch (param.type) {
        case 'assign-application':
          await this.gatherApplicationData();
          break;
      }
    }
  },
  components: {
    ApplicationAssignment,
    ApplicationList,
    ScrollerWrap,
    LoadingSection,
    LORListByStatus,
  },
  computed: {
    ...mapGetters([
      "getPusherNotification",
      "getAssignedApplications"
    ]),
    assignedAppCount() {
      if (this.getAssignedApplications == null) return;
      return this.getAssignedApplications.length;
    },
  },
  methods: {
    ...mapActions([
      "getLoggedInUser",
      "fetchAssignedApplications",
      "getFailedEvents",
      "fetchUserPermissions",
    ]),
    ...mapMutations(["setApplicationUuid"]),
    changeTab(newTab) {
      this.currentTab = newTab;
    },
    handleApplicationSelect(uuid) {
      this.setApplicationUuid(uuid);
      this.$router.push({
        name: "Application Focus",
        params: {
          uuid: uuid
        }
      });
    },
    async gatherApplicationData() {
      await this.getLoggedInUser().catch(err => console.log(err));
      await this.fetchAssignedApplications().catch(err =>
          console.log(err)
      );
    }
  },
  async beforeMount() {
    await this.gatherApplicationData();
    await this.fetchUserPermissions();
  }
};
</script>
<style lang="scss" scoped>
.dashboard {
  // padding-bottom: 200px;
}

.btn {
  &-top-margin {
    margin-top: 38px;
  }
}
</style>
